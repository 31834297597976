<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    PageHeader,
  },
  data() {
    return {
      HrEmployeeTransfers: [],
      HrEmployees: [],
      HrDepartments: [],
      HrJobTitles: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchHrEmployeeTransfers: null,
      HrEmployeeTransfersSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    transfersEmployee(app) {
      let departments = this.HrDepartments.filter(
        (department) => department.id != app?.hr_department?.id
      );
      var data = {
        title: "popups.transfer",
        inputs: [
          {
            model: "from_hr_department_id",
            type: "text",
            label: "From Department",
            value: app?.hr_department?.name,
          },
          {
            model: "to_hr_department_id",
            type: "select",
            options: (() => {
              return departments.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "To Department",
          },
          {
            model: "from_salary",
            type: "text",
            label: "From Salary",
            value: app?.hr_employee?.basic_salary,
          },
          { model: "to_salary", type: "number", label: "To Salary" },
          { model: "date", type: "date", label: "Date" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.hr_employee_id = app?.hr_employee?.id;
              obj.from_hr_department_id = app?.hr_department?.id;
              this.http.post("hr-employee-transfers", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.HrEmployeeTransfersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("hr-employee-transfers/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.HrEmployees = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.HrEmployeeTransfersSearchMode = false;
      this.get(this.page);
    },
    getHrEmployeeTransfers() {
      this.http.get("hr-employee-transfers").then((res) => {
        this.HrEmployeeTransfers = res.data;
      });
    },
    getHrEmployeesAndDepartments() {
      this.http.get("hr-employees/hr-jobs-departments").then((resp) => {
        this.HrDepartments = resp.hrDepartments;
        this.HrJobTitles = resp.hrJobTitles;
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("hr-employees/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.HrEmployees = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getHrEmployeesAndDepartments();
  },
};
</script>

<template>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.hr.subItems.transfares')"
    />
    <div class="row mb-4">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="HrEmployeeTransfersSearchMode"
          @click="cancelappsearchMode()"
        ></button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("hr_employees.id") }}</th>
          <th scope="col">{{ $t("hr_employees.name") }}</th>
          <th scope="col">{{ $t("hr_employees.birthdate") }}</th>
          <th scope="col">{{ $t("hr_employees.basic_salary") }}</th>
          <th scope="col">{{ $t("hr_employees.job_title") }}</th>
          <th scope="col">{{ $t("hr_employees.department") }}</th>
          <th scope="col">{{ $t("hr_employees.created") }}</th>
          <th scope="col">{{ $t("hr_employees.updated") }}</th>
          <th scope="col">{{ $t("hr_employees.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="app in HrEmployees" :key="app" class="text-center">
          <td>{{ app?.hr_employee?.id }}</td>
          <td>{{ app?.hr_employee?.name }}</td>
          <td>{{ app?.hr_employee?.birthdate }}</td>
          <td>{{ app?.hr_employee?.basic_salary }}</td>
          <td>{{ app?.hr_job_title?.name }}</td>
          <td>{{ app?.hr_department?.name }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>

          <td class="d-flex">
            <a
              class="btn btn-primary"
              href="javascript: void(0);"
              role="button"
              @click="transfersEmployee(app)"
              >{{ $t("popups.transfer") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages >= 20">
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>

</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
